import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { apiUrl } from '../services/http/http';

import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import ProfilesCompareButterfly from './ProfilesCompareButterfly';
import user_icon from './user_icon.png';
import ReactTooltip from 'react-tooltip';
import { ProfileComparisonModal } from '../../shared/components/ProfileComparisonModal';

export const ProfilesCompare = props => {
  let history = useHistory();
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];
  const profile1Id = props.location.pathname.split('/')[6];
  const profile2Id = props.location.pathname.split('/')[7];
  //var expertise_levels = [];
  var expertise_levels_legend = [];

  const [profile1, setProfile1] = useState();
  const [profile2, setProfile2] = useState();
  const [profiles, setProfiles] = useState();
  // const [totalCount, setTotalCount] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [comparisonError, setComparisonError] = useState(null);
  const [framework, setFramework] = useState();
  const [isGuestProfile, setIsGuestProfile] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  const [link1, setLink1] = useState();
  const [link2, setLink2] = useState();

  // var includeSummary = [];

  const redirectToCompare = e => {
    if (!profile1.id) {
      profile1.id = 'guest';
    }
    if (selectedProfileId) {
      history.push(
        `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/${
          profile1.id
        }/${selectedProfileId}`
      );
    } else {
      setComparisonError('Select a role to compare');
    }
  };

  // useEffect(() => {
  //   const getSummary = () => {
  //     if (framework) {
  //       framework.map(item =>
  //         item.domains.map(domain =>
  //           domain.competencies.map(competency => {
  //             let profile1Expertise = getExpertise(competency.id, 'profile1');
  //             let profile2Expertise = getExpertise(competency.id, 'profile2');

  //             if (profile1Expertise && profile2Expertise) {
  //               if (
  //                 profile1Expertise.rating_level <
  //                 profile2Expertise.rating_level
  //               ) {
  //                 includeSummary.push(competency.id);
  //                 setTotalCount(includeSummary.length);
  //               }
  //             }
  //             if (!profile1Expertise && profile2Expertise) {
  //               includeSummary.push(competency.id);
  //               setTotalCount(includeSummary.length);
  //             }

  //             return null;
  //           })
  //         )
  //       );
  //     }
  //   };
  //   const getExpertise = (competency, profileid) => {
  //     let mapping = [];
  //     if (profileid === 'profile1') {
  //       mapping = profile1?.profile_mapping;
  //     } else {
  //       mapping = profile2?.profile_mapping;
  //     }

  //     let obj = mapping.find(o => o.competency === competency);
  //     if (obj) {
  //       if (frameworkInfo) {
  //         let frm = frameworkInfo.find(
  //           info => info.title.toLowerCase() === frameworkName
  //         );

  //         let expertise = frm.expertise_levels.find(
  //           level => level.id === obj.expertise
  //         );
  //         // Levels = frm.expertise_levels.length;
  //         return expertise;
  //       }
  //     } else {
  //       return null;
  //     }
  //   };
  //   getSummary();
  // }, [
  //   includeSummary,
  //   framework,
  //   frameworkInfo,
  //   frameworkName,
  //   profile1,
  //   profile2
  // ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile1Id === 'guest') {
          await setProfile1(JSON.parse(localStorage.getItem('guestProfile')));
          setIsGuestProfile(1);
          setLink1(
            `/framework/${frameworkName}/${frameworkVersion}/profile/view/guest`
          );
        } else {
          setLink1(
            `/framework/${frameworkName}/${frameworkVersion}/profile/view/${profile1Id}/alias`
          );
          await fetch(
            `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles?_format=json&id=${profile1Id}&source=competencyhub`
          )
            .then(Response => Response.json())
            .then(findresponse => {
              setProfile1(findresponse);
            });
        }

        if (profile2Id === 'guest') {
          await setProfile2(JSON.parse(localStorage.getItem('guestProfile')));
          setIsGuestProfile(1);
          setLink2(
            `/framework/${frameworkName}/${frameworkVersion}/profile/view/guest`
          );
        } else {
          setLink2(
            `/framework/${frameworkName}/${frameworkVersion}/profile/view/${profile2Id}/alias`
          );
          await fetch(
            `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles?_format=json&id=${profile2Id}&source=competencyhub`
          )
            .then(Response => Response.json())
            .then(findresponse => {
              setProfile2(findresponse);
            });
        }

        await fetch(
          `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles/?_format=json&source=competencyhub`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            const profilesExcludingCurrentProfile = findresponse.filter(
              p =>
                // p.id !== props.match.params.profile1 &&
                p.id !== profile1Id && p.publishing_status === 'Live'
            );
            setProfiles(profilesExcludingCurrentProfile);
          });

        await fetch(
          `${apiUrl}/api/version_manager?_format=json&source=competencyhub`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            setFrameworkInfo(findresponse);
          });

        await fetch(
          `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            setFramework(findresponse);
          });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [frameworkName, frameworkVersion, profile1Id, profile2Id]);

  if (frameworkInfo) {
    frameworkInfo.forEach((info, index) => {
      if (info.title.toLowerCase() === frameworkName) {
        info.expertise_levels.map((level, levelIndex) => {
          if (level.title !== 'Not applicable') {
            expertise_levels_legend.push(
              <li key={levelIndex} className="vf-list__item">
                <div
                  data-tip={level.description ? level.description : 'NA'}
                  data-html={true}
                  data-type="info"
                  data-multiline={true}
                >
                  <span className="vf-badge vf-badge--tertiary">
                    {level.rating_level}
                  </span>
                  <div> {level.title}</div>
                </div>
                <ReactTooltip className="tooltip-custom" />
              </li>
            );
          }
          return null;
        });
      }
    });
  }

  // const handleSummary = e => {
  //   e.preventDefault();
  //   history.push({
  //     pathname: `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${
  //       profile2.id
  //     }/summary`,
  //     state: {
  //       profileJobTitle: profile2.job_title,
  //       includeSummary: includeSummary,
  //       refProfileID: profile2.id
  //     }
  //   });
  // };

  const getCount = () => {
    let needsImprovement = 0;
    if (profile1 && profile2) {
      let p1_mapping = profile1.profile_mapping;
      let p2_mapping = profile2.profile_mapping;
      p1_mapping.map(item =>
        p2_mapping.map(item2 => {
          if (item2.competency === item.competency) {
            item.expertise < item2.expertise
              ? (needsImprovement = needsImprovement + 1)
              : (needsImprovement = needsImprovement + 0);
          }
          return null;
        })
      );
    }

    return needsImprovement;
  };

  return (
    <div>
      <h1>Compare career profiles</h1>
      <span className="lead">
        Compare profile with other reference profiles to help you make career
        choices based on your competency
      </span>
      <div className="vf-u-margin__top--800" />
      <div className="vf-grid vf-u-background-color-ui--grey--light">
        <div className="vf-u-padding--400">
          <div className="vf-u-margin__bottom--1200" />
          {profile1 ? (
            <div className="vf-profile vf-profile--small vf-profile--inline">
              <img
                alt="profile1"
                src={profile1.image[0] ? profile1.image[0].url : user_icon}
                className="vf-profile__image"
              />
              <Link to={link1 ? link1 : '#'}>
                <h3 className="vf-profile__title">{profile1.job_title}</h3>
              </Link>
            </div>
          ) : (
            'loading profiles'
          )}
        </div>

        <div className="vf-u-padding--400">
          <div className="vf-u-margin__bottom--1200" />
          {profile2 ? (
            <div className="vf-profile vf-profile--small vf-profile--inline">
              <img
                alt="profile2"
                src={profile2.image[0] ? profile2.image[0].url : user_icon}
                className="vf-profile__image"
              />
              <Link to={link2}>
                <h3 className="vf-profile__title">{profile2.job_title}</h3>
              </Link>

              <ProfileComparisonModal
                profiles={profiles}
                profile={profile1}
                comparisonError={comparisonError}
                linkVariant={true}
                setSelectedProfileId={id => {
                  setSelectedProfileId(id);
                }}
                redirectToCompare={() => {
                  redirectToCompare();
                }}
              />
            </div>
          ) : (
            'loading profiles'
          )}
        </div>
        <div className="vf-u-background-color-ui--white vf-u-padding--400">
          <h3>Summary</h3>
          <div>
            {profile1 && profile2 ? (
              <>
                Career path from {profile1.job_title} to {profile2.job_title}
                <p />
                <strong>{getCount()}</strong> competency areas need improvement
              </>
            ) : (
              ''
            )}

            {/* <div className="vf-u-margin__top--200" />
            <a href="" className='vf-button vf-button--sm vf-button--primary'>Explore training resources</a> */}
          </div>
        </div>
      </div>
      <div className="vf-u-margin__top--1200" />
      {/* {profile1Id === 'guest' || profile2Id === 'guest' ? (
        <>
          <h2>Summary</h2>

          <p>
            To plan a career path from <strong>{profile1?.job_title}</strong> to{' '}
            <strong>{profile2?.job_title}</strong>
          </p>

          <p>{totalCount} Competency areas need improvement</p>
          <div style={{ textAling: 'center' }}>
            <button
              className="vf-button vf-button--primary vf-button--sm"
              onClick={e => handleSummary(e)}
            >
              View suggested training resources
            </button>
          </div>
        </>
      ) : (
        ''
      )} */}

      <div className="vf-u-margin__top--1200" />
      <h3>Scale</h3>
      <p>A guide to assign your expertise</p>
      <ul className="vf-list legend-inline">{expertise_levels_legend}</ul>

      {profile1 && profile2 ? (
        <ProfilesCompareButterfly
          frameworkName={frameworkName}
          frameworkVersion={frameworkVersion}
          profile1Id={profile1Id}
          profile2Id={profile2Id}
          profile1={profile1}
          profile2={profile2}
          framework={framework}
          frameworkInfo={frameworkInfo}
          isGuestProfile={isGuestProfile}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export const sendRoute = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profiles/compare/:profile1/:profile2"
      component={ProfilesCompare}
    />
  </Switch>
);

export default sendRoute;
