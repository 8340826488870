import React from 'react';
import axios from 'axios';
import Parser from 'html-react-parser';
import { withSnackbar } from 'notistack';
import ActiveRequestsService from '../services/active-requests/active-requests';
import CompetencyService from '../services/competency/competency';
import CoursesService from '../services/courses/courses';
// import { slugify } from '../services/util/slugifier';
import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import Pagination from 'react-js-pagination';

class CompetencyDetails extends React.Component {
  activeRequests = new ActiveRequestsService();
  competencyService = new CompetencyService();
  coursesService = new CoursesService();

  state = {
    framework: this.props.match.params.framework,
    version: this.props.location.pathname.split('/'),
    frameworkData: [],
    competencyId: this.props.match.params.cid,
    attributeDefs: [],
    resources: [],
    competency: [],
    filterType: '',
    filterTitle: '',
    search: '',
    totalItems: 0,
    page: 0,
    loading: false
  };

  async componentDidMount() {
    try {
      window.vfTabs();
      this.activeRequests.startRequest();
      await Promise.all([
        this.getFramework(),
        this.getAttributes(),
        this.getResources()
      ]).then(() => {
        const params = new URLSearchParams(window.location.search);
        let scroll = params.get('scroll');
        if (scroll === 'true') {
          let element = document.getElementById('training-resources-block');
          if (element) {
            element.scrollIntoView();
            element.scrollIntoView(false);
            element.scrollIntoView({ block: 'start' });
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          }
        }
      });
    } finally {
      this.activeRequests.finishRequest();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.filterType === nextState.filterType;
  }

  async getFramework() {
    const { framework } = this.state;
    const { version } = this.state;
    const frameworkData = await this.competencyService.getVersionedFramework(
      framework,
      version[3]
    );

    this.setState({
      frameworkData
    });
  }
  // getAllVersionedFrameworks.
  // To be tested
  async getAttributes() {
    // const allFrameworks = await this.competencyService.getAllFrameworks();
    const allFrameworks = await this.competencyService.getAllVersionedFrameworks();
    const { framework } = this.state;
    const frameworkMatch = allFrameworks.filter(
      item => item.machine_name === framework.replace(/ /g, '')
    );

    if (frameworkMatch.length) {
      const attributeDefs = frameworkMatch[0].attribute_types.map(
        attribute => attribute.title
      );
      this.setState({ attributeDefs });
    }
  }

  async getResources() {
    try {
      this.setState({ loading: true });
      const resources = await axios.get(
        `https://dev-cms.competency.ebi.ac.uk/api/competency-resources?field_competency_target_id=${
          this.state.competencyId
        }${
          this.state.filterType
            ? '&field_type_value=' + this.state.filterType
            : ''
        }${this.state.search ? '&title=' + this.state.search : ''}${
          this.state.page ? '&page=' + this.state.page : '&page=0'
        }`
      );
      this.setState({ resources: resources.data });

      const allResources = await axios.get(
        `https://dev-cms.competency.ebi.ac.uk/api/competency-resources/count?_format=json&field_competency_target_id=${
          this.state.competencyId
        }${
          this.state.filterType
            ? '&field_type_value=' + this.state.filterType
            : ''
        }${this.state.search ? '&title=' + this.state.search : ''}`
      );
      this.setState({ resources: resources.data });
      this.setState({ totalItems: allResources.data.length });
      this.setState({ loading: false });
    } catch (error) {
      this.props.enqueueSnackbar('Unable to retrieve training resources', {
        variant: 'error'
      });
      this.setState({ loading: false });
    }
  }

  dateTimeClean(start, end) {
    let start_year = start.slice(0, 4);
    let start_month = start.slice(5, 7);
    let start_date = start.slice(8, 10);

    let end_year = end.slice(0, 4);
    let end_month = end.slice(5, 7);
    let end_date = end.slice(8, 10);

    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (end_date === '') {
      return start_date + ' ' + months[start_month - 1] + ' ' + start_year;
    }

    if (
      start_year === end_year &&
      start_month === end_month &&
      start_date === end_date
    ) {
      return start_date + ' ' + months[start_month - 1] + ' ' + start_year;
    }

    if (start_year === end_year && start_month === end_month) {
      return (
        start_date +
        ' - ' +
        end_date +
        ' ' +
        months[start_month - 1] +
        ' ' +
        start_year
      );
    }

    if (start_year === end_year) {
      return (
        start_date +
        ' ' +
        months[start_month - 1] +
        ' - ' +
        end_date +
        ' ' +
        months[end_month - 1] +
        ' ' +
        start_year
      );
    }

    return (
      start_date +
      ' ' +
      months[start_month - 1] +
      ' ' +
      start_year +
      ' - ' +
      end_date +
      ' ' +
      months[end_month - 1] +
      ' ' +
      end_year
    );
  }

  async click(e) {
    let target = e.target;
    if (target.checked) {
      await this.setState({ filterType: e.target.value });
    } else {
      await this.setState({ filterType: '' });
    }
    await this.setState({ page: 0 });
    await this.getResources();
  }

  async handleSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  async clickSearch(e) {
    e.preventDefault();
    await this.setState({ page: 0 });
    await this.getResources();
  }

  resourceBlock() {
    const { resources } = this.state;
    if (resources.length === 0) {
      return 'No resources found';
    }
    return resources.map(resource => (
      <div className="vf-summary vf-summary--event" key={resource.nid}>
        <div>
          <p className="vf-summary__date">{resource.type}</p>
          <h3 className="vf-summary__title">
            <a
              href={`/training-resources/${resource.view_node.substr(10)}`}
              className="vf-summary__link"
            >
              {resource.title}
            </a>
          </h3>
          <div className="vf-summary__text">
            {resource.description !== ''
              ? Parser(resource.description) + '...'
              : ''}
          </div>
        </div>
        <div className="vf-u-margin__top--600" />

        <div className="vf-summary__location">
          {resource.start_date !== '' ? (
            <span>
              {this.dateTimeClean(resource.start_date, resource.end_date)}
            </span>
          ) : (
            ''
          )}
          <span>
            {resource.start_date && resource.location ? ' | ' : ''}
            {resource.location !== ''
              ? resource.location
              : resource.type === 'Online'
              ? ' | Online'
              : ''}
          </span>
        </div>
      </div>
    ));
  }

  async handlePageChange(page) {
    console.log(page);
    await this.setState({ page: page - 1 });
    await this.getResources();
  }

  render() {
    const {
      competencyId,
      frameworkData,
      attributeDefs,
      resources,
      totalItems,
      loading
    } = this.state;

    const competencies = [];
    frameworkData.forEach(item =>
      item.domains.forEach(domain =>
        domain.competencies.forEach(competency => {
          if (competency.id === competencyId) {
            competencies.push({
              ...competency,
              framework: item.title,
              domain: domain.title
            });
          }
        })
      )
    );

    const competencyDetails = competencies.map(competency => (
      <div key={competency.id}>
        <div className="vf-grid">
          <div>
            <ul>
              {attributeDefs.map(def => {
                return (
                  <div key={def}>
                    <div className="margin-top-medium">
                      {' '}
                      <h3>{def}</h3>
                    </div>
                    {competency.attributes.map(attribute => {
                      if (attribute.type === def) {
                        return <li key={attribute.id}>{attribute.title} </li>;
                      }
                      return null;
                    })}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    ));

    return (
      <div>
        {competencies.length > 0 ? (
          <>
            <MetaTags>
              <title>{competencies[0].title}</title>
              <meta name="description" content={competencies[0].title} />
              <meta property="og:title" content={competencies[0].title} />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={`https://competency.ebi.ac.uk${
                  this.props.history.location.pathname
                }`}
              />
              <meta
                property="og:image"
                content="https://acxngcvroo.cloudimg.io/v7/https://cms.competency.ebi.ac.uk/themes/custom/ebi_academy/images/mastheads/CH_Jigsaw.jpg"
              />
              <meta
                property="og:description"
                content="Details of a competency e.g. attributes and associated training resources"
              />
              <meta
                property="keywords"
                content={`competency, competency-based training, ${
                  competencies[0].domain
                }, ${
                  competencies[0].framework === 'ISCB'
                    ? 'computational biology competencies'
                    : ''
                }`}
              />
            </MetaTags>
            <Helmet>
              <link rel="canonical" href={this.props.location.pathname} />
            </Helmet>
          </>
        ) : (
          ''
        )}
        <div className="vf-u-margin__top--800" />
        <h1>{competencies[0] ? competencies[0].title : ''}</h1>
        <div className="vf-u-margin__top--800" />

        <div>
          <div className="vf-tabs">
            <ul className="vf-tabs__list" data-vf-js-tabs>
              <li className="vf-tabs__item">
                <a className="vf-tabs__link" href="#vf-tabs__section--1">
                  Competency attributes
                </a>
              </li>
              <li className="vf-tabs__item">
                <a className="vf-tabs__link" href="#vf-tabs__section--2">
                  Training resources
                </a>
              </li>
            </ul>
          </div>

          <div className="vf-tabs-content" data-vf-js-tabs-content>
            <section className="vf-tabs__section" id="vf-tabs__section--1">
              {competencyDetails}
              <hr />
              <div>
                <h2>Competency derived from:</h2>
                <p>
                  {' '}
                  {competencies[0] && competencies[0].mapped_other_competency
                    ? Parser(competencies[0].mapped_other_competency)
                    : 'No data available'}{' '}
                </p>
              </div>
            </section>
            <section className="vf-tabs__section" id="vf-tabs__section--2">
              <div id="training-resources-block">
                <div className="vf-u-margin__top--800" />
                <h2>Training resources associated with this competency</h2>
                <div className="vf-u-margin__top--800" />
                <div className="vf-grid vf-grid__col-4">
                  <div className="vf-grid__col--span-1">
                    <h3>Refine by</h3>
                    <div className="vf-u-margin__top--1200" />
                    <h4>Type</h4>
                    <ul className="vf-list">
                      <li key={'online'} className="vf-list--item">
                        <div className="vf-form__item vf-form__item--radio">
                          <input
                            type="radio"
                            id="all"
                            name="type"
                            onChange={e => this.click(e)}
                            className="vf-form__radio"
                            data-facetid={'facetid'}
                            data-facetlabel={'facetlabel'}
                            data-domain={'domain'}
                            value=""
                            // checked={facetsFromURL?.indexOf(facetid + ":" + facetlabel) !== -1}
                            // disabled={disabled}
                            style={{ position: 'absolute' }}
                          />
                          <label
                            htmlFor={'all'}
                            data-id={'all'}
                            data-domain={'domain'}
                            className="vf-form__label"
                          >
                            {'All'}
                          </label>
                        </div>
                      </li>
                      <li key={'online'} className="vf-list--item">
                        <div className="vf-form__item vf-form__item--radio">
                          <input
                            type="radio"
                            id="online"
                            name="type"
                            onChange={e => this.click(e)}
                            className="vf-form__radio"
                            data-facetid={'facetid'}
                            data-facetlabel={'facetlabel'}
                            data-domain={'domain'}
                            value="Online"
                            // checked={facetsFromURL?.indexOf(facetid + ":" + facetlabel) !== -1}
                            // disabled={disabled}
                            style={{ position: 'absolute' }}
                          />
                          <label
                            htmlFor={'online'}
                            data-id={'online'}
                            data-domain={'domain'}
                            className="vf-form__label"
                          >
                            {'Online'}
                          </label>
                        </div>
                      </li>
                      <li key={'face-to-face'} className="vf-list--item">
                        <div className="vf-form__item vf-form__item--radio">
                          <input
                            type="radio"
                            id="face-to-face"
                            name="type"
                            value="Face-to-Face"
                            onChange={e => this.click(e)}
                            className="vf-form__radio"
                            data-facetid={'facetid'}
                            data-facetlabel={'facetlabel'}
                            data-domain={'domain'}
                            // checked={facetsFromURL?.indexOf(facetid + ":" + facetlabel) !== -1}
                            // disabled={disabled}
                            style={{ position: 'absolute' }}
                          />
                          <label
                            htmlFor={'face-to-face'}
                            data-id={'face-to-face'}
                            data-domain={'domain'}
                            className="vf-form__label"
                          >
                            {'Face-to-Face'}
                          </label>
                        </div>
                      </li>
                      <li key={'webinar'}>
                        <div className="vf-form__item vf-form__item--radio">
                          <input
                            type="radio"
                            id="webinar"
                            name="type"
                            onChange={e => this.click(e)}
                            className="vf-form__radio"
                            data-facetid={'facetid'}
                            data-facetlabel={'facetlabel'}
                            data-domain={'domain'}
                            // checked={facetsFromURL?.indexOf(facetid + ":" + facetlabel) !== -1}
                            // disabled={disabled}
                            style={{ position: 'absolute' }}
                          />
                          <label
                            htmlFor={'webinar'}
                            data-id={'webinar'}
                            data-domain={'domain'}
                            className="vf-form__label"
                          >
                            {'Webinar'}
                          </label>
                        </div>
                      </li>
                      <li key={'hackathon'}>
                        <div className="vf-form__item vf-form__item--radio">
                          <input
                            type="radio"
                            id="hackathon"
                            name="type"
                            onChange={e => this.click(e)}
                            className="vf-form__radio"
                            data-facetid={'facetid'}
                            data-facetlabel={'facetlabel'}
                            data-domain={'domain'}
                            // checked={facetsFromURL?.indexOf(facetid + ":" + facetlabel) !== -1}
                            // disabled={disabled}
                            style={{ position: 'absolute' }}
                          />
                          <label
                            htmlFor={'hackathon'}
                            data-id={'hackathon'}
                            data-domain={'domain'}
                            className="vf-form__label"
                          >
                            {'Hackathon'}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {loading ? (
                    <center>
                      <img alt="progress" src="/progressbar.gif" />
                      <h4>Loading resources</h4>
                    </center>
                  ) : (
                    <div className="vf-grid__col--span-3">
                      <form
                        action="#"
                        className="vf-form vf-form--search vf-form--search--responsive | vf-sidebar vf-sidebar--end"
                        onSubmit={e => this.clickSearch(e)}
                      >
                        <div className="vf-sidebar__inner">
                          <div className="vf-form__item">
                            <label
                              className="vf-form__label vf-u-sr-only | vf-search__label"
                              htmlFor="searchitem"
                            >
                              Search
                            </label>
                            <input
                              type="search"
                              placeholder="Enter your search terms"
                              id="searchitem"
                              className="vf-form__input"
                              value={this.state.search}
                              onChange={event => this.handleSearchChange(event)}
                            />
                          </div>
                          <button
                            type="submit"
                            className="vf-search__button | vf-button vf-button--primary"
                          >
                            <span className="vf-button__text">Search</span>
                          </button>
                        </div>
                      </form>

                      <div className="vf-u-margin__top--800" />
                      {resources.length > 0 ? (
                        <div className="vf-grid">
                          <p>
                            Showing:{' '}
                            <strong>
                              {this.state.page === 0
                                ? 1
                                : this.state.page * 10 + 1}{' '}
                              -{' '}
                              {this.state.totalItems < 10
                                ? this.state.totalItems
                                : this.state.page === 0
                                ? 10
                                : (this.state.page + 1) * 10}{' '}
                            </strong>{' '}
                            results of <strong> {this.state.totalItems}</strong>{' '}
                            items
                          </p>
                          <p>Sorted by: Last updated</p>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="vf-u-margin__top--800" />
                      {this.resourceBlock()}
                      {totalItems > 10 ? (
                        <>
                          <nav
                            className="vf-pagination"
                            aria-label="Pagination"
                          >
                            <Pagination
                              activePage={this.state.page + 1}
                              itemsCountPerPage={10}
                              totalItemsCount={this.state.totalItems - 1}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange.bind(this)}
                              innerClass="vf-pagination__list"
                              itemClass="vf-pagination__item"
                              itemClassPrev="vf-pagination__item--previous-page"
                              itemClassNext="vf-pagination__item--next-page"
                              linkClass="vf-pagination__link vf-pagination__label"
                              activeClass="vf-pagination__item--is-active"
                              prevPageText="Previous"
                              nextPageText="Next"
                            />
                          </nav>
                          <div className="vf-u-margin__top--800" />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
              </div>
              {this.state.resources.length > 0 ? (
                <p>
                  These learning resources are suggestions curated to help you
                  enhance competencies related to your career profile.
                  Completion of these resources is not a guarantee of mastery or
                  job placement.
                </p>
              ) : (
                ''
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(CompetencyDetails);
